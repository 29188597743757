<ng-container *ngIf="files$ | async as files">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <p *ngIf="data.message">{{ data.message }}</p>

    <div class="file-drop">
      <div class="hint mb-2">Drop files here</div>

      <input
        id="file_input"
        class="file-input"
        (change)="fileInputChanged($event)"
        type="file"
        #fileInput
        [multiple]="data.multiple"
        [accept]="data.accept"
        title=""
      />
      <button mat-flat-button type="button" (click)="fileInput.click()">Select</button>
    </div>

    <xd-list-item
      *ngFor="let file of files; index as i"
      icon="file-alt"
      class="file-list-item"
      [class.invalid]="!validateFile(file)"
    >
      <span xdHeadline>{{ file.name }}</span>
      <button mat-icon-button (click)="removeFile(i)">
        <fa-icon icon="trash"></fa-icon>
      </button>
    </xd-list-item>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button (click)="cancel()">
      {{ data.cancelLabel }}
    </button>
    <button color="primary" mat-flat-button (click)="submit()" [disabled]="!valid">
      {{ data.submitLabel }}
    </button>
  </div>
</ng-container>
